<template>
  <div style="position: absolute; left: 23%; top: 25%;">
    <div>
      <span style="font-family: Ubuntu; font-weight: bold; color: #ffffff; font-size: 350%;">Welcome to GL</span>
      <span style="font-family: Ubuntu; font-weight: bold; color: #d72600; font-size: 350%;">Uno</span>
      <span style="font-family: Ubuntu; font-weight: bold; color: #ffffff; font-size: 350%;">.</span>
    </div>
    <div style="width: 100%; margin-bottom: 5%;">
      <span style="font-family: Open Sans; font-weight: light; color: #ffffff; font-size: 120%;">The famous card game at the tip of your fingers</span>
    </div>
    <div v-if="hasToken == true" style="margin-bottom: 5vh;">
      <div v-if="joinedRoom == null" style="margin-bottom: 5%;">
        <span style="font-family: Open Sans; font-weight: bold; color: #ffffff; font-size: 75%; float: left;">Create a new room or join one below</span>
        <v-btn color="green" @click="createRoom()" style="float: right;" x-small dark>Create new Room</v-btn>
      </div>
      <br v-if="joinedRoom == null"/>
      <div v-if="joinedRoom == null" class="roomsList" style="overflow-y: auto; height: 46vh;">
        <v-card v-for="room in roomsList" v-bind:key="room.id" dark color="#000000" width="100%" style="border-color: #1f2937; margin-bottom: 2%; margin-top: 1%" outlined>
          <div class="d-flex flex-no-wrap">
            <v-avatar class="ma-3" height="100%" rounded>
              <v-img v-if="room.count == 4 || room.started == true" src="https://cdn.discordapp.com/attachments/890547395606216724/890563134954299402/UnoLogoBW.png"></v-img>
              <v-img v-else src="https://cdn.discordapp.com/attachments/890547395606216724/890563121071128636/UnoLogo.png"></v-img>
            </v-avatar>
            <div class="ma-3">
              <span v-if="room.private == true" style="font-family: Open Sans; font-weight: bold; color: #ffffff; font-size: 100%;"><v-icon size="100%" color="white" style="margin-bottom: 3.5%">mdi-lock</v-icon> Room {{ room.url }}</span>
              <span v-else style="font-family: Open Sans; font-weight: bold; color: #ffffff; font-size: 100%;"><v-icon size="120%" color="white" style="margin-bottom: 3.5%">mdi-account-group</v-icon> Room {{ room.url }}</span>
              <p style="font-family: Open Sans; font-weight: regular; color: #ffffff; font-size: 80%;">{{ room.players }}</p> 
            </div>
            <v-spacer></v-spacer>
            <div class="ma-3">
              <span v-if="room.count == 4 && room.started == false" style="font-family: Open Sans; font-weight: bold; color: #ffffff; font-size: 80%; float: right;">Room Full</span>
              <span v-if="room.started == true" style="font-family: Open Sans; font-weight: bold; color: #ffffff; font-size: 80%; float: right;">Game Started</span>
              <span v-if="room.count < 4 && room.private == false && room.started == false" style="font-family: Open Sans; font-weight: bold; color: #ffffff; font-size: 80%; float: right;">No Password Required</span>
              <span v-if="room.count < 4 && room.private == true && room.started == false" style="font-family: Open Sans; font-weight: bold; color: #ffffff; font-size: 80%; float: right;">Password Required</span>
              <br/>
              <v-btn v-if="room.started == true" color="blue" :href="domain + 'room/' + room.url" style="top: 1%;" x-small :disabled="joinedRoom !== null">Spectate Room</v-btn>
              <v-btn v-if="room.private == false && room.started == false" :href="domain +'room/' + room.url" color="green" style="top: 1%;" x-small :disabled="room.count == 4 || joinedRoom !== null">Join Public Room [{{ room.count }}/4]</v-btn>
              <v-btn v-if="room.private == true && room.started == false" :href="domain + 'room/' + room.url" color="green" style="top: 1%;" x-small :disabled="room.count == 4 || joinedRoom !== null">Join Private Room [{{ room.count }}/4]</v-btn>
            </div>
          </div>
        </v-card>
      </div>
    </div>
    <div v-else style="margin-bottom: 5vh;">
      <div style="margin-bottom: 5%">
        <span id="nameField" style="font-family: Open Sans; font-weight: bold; color: #ffffff; font-size: 75%; float: left;">Please enter a nickname to continue</span>
        <v-btn color="green" @click="setName()" style="float: right;" x-small dark :disabled="nameInput == ''">Lets Play!</v-btn>
        <br/>
        <v-text-field v-model="nameInput" :error="tokenError != ''" :hint="tokenError" persistent-hint maxlength="20" counter dark></v-text-field>
      </div>
    </div>
  </div>
</template>

<script>
import io from "socket.io-client";
export default {
  data: () => ({
    domain: "",
    roomsList: [],
    name: "",
    tokenError: "",
    hasToken: false,
    nameInput: "",
    socket: io("backend.gl-uno.com"),
    joinedRoom: null,
    isRoomOwner: false,
  }),
  mounted: function () {
    var processData = this;

    processData.domain = window.location.href;

    if (localStorage.getItem('userToken')) {
      processData.socket.emit("validToken", { token: localStorage.getItem('userToken') }, function (data) {
        if (data == true) {
          processData.hasToken = true;
        } else {
          localStorage.removeItem('userToken')
        }
      })
    }

    processData.socket.emit("getData", { token: localStorage.getItem('userToken') }, function (data) {
      if (data) {
        for (var key in data) {
          processData[key] = data[key];
          processData.roomsList = [];
          Object.keys(data["rooms"]).forEach(function(k) {
            let roomArray = data["rooms"][k].roomInfo;
            processData.roomsList.push(roomArray);
          })
        }
      } else {
        console.log("No Data");
      }
    });

    processData.socket.emit("setInRoom", { token: localStorage.getItem('userToken'), room: null }, function () {

    });

    setInterval(function () {
      processData.socket.emit("getData", { token: localStorage.getItem('userToken') }, function (data) {
        if (data) {
          for (var key in data) {
            processData[key] = data[key];
            processData.roomsList = [];
            Object.keys(data["rooms"]).forEach(function(k) {
              let roomArray = data["rooms"][k].roomInfo;
              processData.roomsList.push(roomArray);
            })
          }
        } else {
          console.log("No Data");
        }
      });
      processData.socket.emit("stillHere", { token: localStorage.getItem('userToken') }, function () {
      });
    }, 1000);
  },
  methods: {
    setName() {
      let processData = this;
      processData.socket.emit("setUserData", { name: processData.nameInput }, function (data) {
        processData.name = processData.nameInput;
        if (data.error == false) {
          localStorage.setItem("userToken", data.token)
          processData.hasToken = true;
        } else {
          processData.tokenError = data.error;
          setTimeout(function () {
            processData.tokenError = "";
          }, 3000);
        }
      })
    },
    createRoom() {
      let processData = this;
      processData.socket.emit("createRoom", { token: localStorage.getItem("userToken") }, function (data) {
        window.location = window.location.href + "room/" + data
      })
    },
  },
};
</script>

<style lang="scss">
html::-webkit-scrollbar {
  display: none;
}

html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.roomsList::-webkit-scrollbar {
  display: none;
}

.roomsList {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.yellowCard:hover {
  border: 5px solid #ffde00;
  border-radius: 20px;
}

.redCard:hover {
  border: 5px solid #ed1c24;
  border-radius: 20px;
}

.blueCard:hover {
  border: 5px solid #0095da;
  border-radius: 20px;
}

.greenCard:hover {
  border: 5px solid #00a651;
  border-radius: 20px;
}

.blackCard:hover {
  border: 5px solid #000000;
  border-radius: 20px;
}

.greyCard:hover {
  border: 5px solid #6d6e71;
  border-radius: 20px;
}
</style>